<template>
  <div
    class="w-100 searchpanel"
    data-testid="searchPanel"
  >
    <label for="search" class="visually-hidden">
      {{ $t('Search') }}
    </label>
    <div class="search-input-group" @click="toogleSearchModal = true">
      <label for="search" class="flex middle-xs">
        <i class="icon-wyszukiwarka search-icon h4 weight-900" />
      </label>
      <input
        ref="search"
        id="search"
        v-model="search"
        @input="makeSearch"
        @blur="$v.search.$touch(); $emit('blur', $event.target.value);"
        @focus="$emit('focus', 'focus');"
        @keyup.enter="toggleQueryFilter"
        class="search-panel-input"
        :placeholder="$t('Type what you are looking for...')"
        type="text"
      >
      <i
        v-if="search"
        class="icon-zamknij pointer cl-accent close-icon"
        @click="search= ''; $emit('blur', '');"
        data-testid="closeSearchPanel"
      />
    </div>
    <div class="overlay fixed w-100" @click="toogleSearchModal = false" v-if="isToggleSearch" />
    <transition name="fade">
      <div class="container search-layer bg-cl-primary" :class="{ 'active' : isToggleSearch }" v-show="isToggleSearch">
        <button
          v-show="search.length && visibleProducts.length"
          @click="toggleQueryFilter"
          class="button__show-all button__show-all--search pointer weight-700 hidden-xs"
        >
          {{ $t('Show all results') }}
        </button>
        <div class="categories">
          <category-panel :categories="categories" v-model="selectedCategoryIds" />
        </div>
        <div class="product-listing row mt20">
          <product-tile
            v-for="(product, index) in visibleProducts"
            :key="product.id"
            :product="product"
            :position="index"
            list="search"
            @click.native="toogleSearchModal = false"
            :omnibus-price="omnibusPriceMap[product.sku]"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import SearchPanel from '@vue-storefront/core/compatibility/components/blocks/SearchPanel/SearchPanel'
import ProductTile from 'theme/components/core/ProductTile'
import VueOfflineMixin from 'vue-offline/mixin'
import CategoryPanel from 'theme/components/core/blocks/Category/CategoryPanel'
import { minLength } from 'vuelidate/lib/validators'
import i18n from '@vue-storefront/i18n'
import { mapGetters } from 'vuex'
import config from 'config'

export default {
  components: {
    ProductTile,
    CategoryPanel
  },
  mixins: [SearchPanel, VueOfflineMixin],
  validations: {
    search: {
      minLength: minLength(3)
    }
  },
  data () {
    return {
      search: '',
      selectedCategoryIds: [],
      toogleSearchModal: true
    }
  },
  computed: {
    ...mapGetters('category-next', ['getMenuCategories']),
    isQueryFilterActive () {
      return this.$store.state.ui.isQueryFilterActive
    },
    isToggleSearch () {
      return (((this.visibleProducts.length && this.categories.length > 1) && this.search.length > 1) && this.toogleSearchModal)
    },
    visibleProducts () {
      const productList = this.products || []
      if (this.selectedCategoryIds.length) {
        return productList.filter(product => product.category_ids && product.category_ids.some(categoryId => {
          const catId = parseInt(categoryId)
          return this.selectedCategoryIds.includes(catId)
        }))
      }
      return productList
    },
    categories () {
      const categoriesMap = {}
      this.products.forEach(product => {
        if (product.category) {
          return [...product.category].forEach(category => {
            categoriesMap[category.category_id] = category
          })
        }
      })
      return Object.keys(categoriesMap).map(categoryId => categoriesMap[categoryId])
    },
    // mainCategory () {
    //   return this.getMenuCategories.find(c => c.id === config.entities.category.categoriesRootCategorylId)
    // },
    getNoResultsMessage () {
      let msg = ''
      if (!this.$v.search.minLength) {
        msg = 'Searched term should consist of at least 3 characters.'
      } else if (this.emptyResults) {
        msg = 'No results were found.'
      }
      return this.$t(msg)
    }
  },
  methods: {
    toggleQueryFilter () {
      this.$router.push({ path: '/' + 'sklep', query: { query: this.search } })
      this.search = ''
    }
  },
  watch: {
    categories () {
      this.selectedCategoryIds = []
    }
  },
  mounted () {
    // this.$refs.search.focus()
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/animations/transitions";
@import "~theme/css/variables/grid";
@import "~theme/css/variables/typography";

.searchpanel {
  .search-layer {
    box-shadow: -4px 4px 8px -4px #808080;
    -webkit-box-shadow: -4px 4px 8px -4px #808080;
    max-height: calc(100vh - 46px);
    top: 70px;
    position: fixed;
    overflow-y: scroll;
    bottom: auto;
    overflow-y: auto;
    z-index: 3;
    right: 0;
    max-width: 740px;
    width: 100%;
    padding: 0;
  }
  .close-icon-row {
    display: flex;
    justify-content: flex-end;
  }

  .container {
    padding-left: 20px;
    padding-right: 20px;

    @media #{$media-xs} {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .row {
    margin-left: - map-get($grid-gutter-widths, lg) / 2;
    margin-right: - map-get($grid-gutter-widths, lg) / 2;

    @media #{$media-xs} {
      margin-right: - map-get($grid-gutter-widths, xs) / 2;
      margin-left: - map-get($grid-gutter-widths, xs) / 2;
    }
  }

  .col-md-12 {
    padding-left: map-get($grid-gutter-widths, lg) / 2;
    padding-right: map-get($grid-gutter-widths, lg) / 2;

    @media #{$media-xs} {
      padding-left: map-get($grid-gutter-widths, xs) / 2;
      padding-right: map-get($grid-gutter-widths, xs) / 2;
    }
  }

  .product {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 33.33%;
    padding-left: map-get($grid-gutter-widths, lg) / 2;
    padding-right: map-get($grid-gutter-widths, lg) / 2;

    @media #{$media-xs} {
      width: 50%;
      padding-left: map-get($grid-gutter-widths, xs) / 2;
      padding-right: map-get($grid-gutter-widths, xs) / 2;
    }
  }

  .close-icon {
    padding: 8px 10px;
  }

  .search-input-group {
    z-index: 2;
    .clear-input {
     width: 37px;
     display: flex;
     align-items: center;
     justify-content: center;
    }
    input {
      width: calc( 100% - 74px);
      height: 29px;
      line-height: 34px;
      outline: none;
    }
    display: flex;
    background-color: #F7F7F7;
    border-radius: 15px;
  }

  .search-icon {
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1396EC;
  }

  .search-panel-input {
    border: none;
    background-color: transparent;

    @media #{$media-xs} {
      font-size: 16px;
    }
  }

  .no-results {
    top: 80px;
    width: 100%;
  }
  @media (hover: hover) {
    i {
      opacity: 0.6;
      &:hover {
        opacity: 1;
      }
    }
  }
  .close-button {
    background: #fff;
  }

  button {
    @media #{$media-xs} {
      width: 100%;
      margin-bottom: 15px;
    }
  }
}

.button__show-all {
    background-color: #1396EC;
    color: #fff;
    border: none;
    border-radius: 15px;
    padding: 10px 15px;
    margin: 15px 0;
}
.overlay {
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0.5;
  z-index: 1;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-in {
  &-left-enter-active,
  &-right-enter-active{
    transition: all 0.3s cubic-bezier(0.4, 0.0, 0.2, 1);
  }

  &-left-leave-active,
  &-right-leave-active {
    transition: all 0.3s cubic-bezier(0.4, 0.0, 1, 1);
  }
}
::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #1396EC;
  border-radius: 15px;
  border: none;
}
</style>
